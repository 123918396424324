$phone-width-lg: 500px;
$tablet-width: 768px;
$desktop-width: 1024px;
$desktop-width-lg: 1367px;
$desktop-width-xl: 1600px;
$desktop-width-full: 1920px;

@mixin phone-lg {
	@media (min-width: #{$phone-width-lg}) {
		@content; } }

@mixin tablet {
	@media (min-width: #{$tablet-width}) {
		@content; } }

@mixin lt-desktop {
	@media (max-width: #{$desktop-width - 1}) {
		@content; } }

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content; } }

@mixin desktop-lg {
	@media (min-width: #{$desktop-width-lg}) {
		@content; } }

@mixin desktop-xl {
	@media (min-width: #{$desktop-width-xl}) {
		@content; } }

@mixin desktop-full {
	@media (min-width: #{$desktop-width-full}) {
		@content; } }

@mixin container {
	padding: 40px;
	display: flex;
	flex-direction: column;
	align-items: center; }
