@import 'styles/index.sass';

.container {
	display: flex;
	flex-direction: column; }

.footer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px;
	background: $color-primary;
	border-top: 1px solid lighten($color-primary, 5%);

	span {
		color: $color-white;
		font-size: 11px;
		opacity: 0.5; } }

.contactContainer {
	background: $color-primary;
	display: flex;
	justify-content: center; }

.contact {
	flex: 1;
	display: flex;
	padding: 20px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	max-width: 800px;

	.contactLink {
		min-width: 200px;
		display: flex;
		padding: 20px 0;
		align-items: center;

		.icon {
			min-width: 38px;
			max-width: 38px;
			display: flex;
			opacity: 0.5; }

		a {
			color: $color-white;
			opacity: 0.95; } } }

@include tablet {
	.contact {
		flex-direction: row; }

	.contactContainer {
		padding: 20px 60px; } }
